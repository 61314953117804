<template>
  <div id="preview">
    <v-card>
      <v-card-title>
        <span class="headline">{{ pageDescription }}</span>
      </v-card-title>

      <v-card-text>
        <v-container class="py-0" v-if="LoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="!LoadingFlag">
          <v-data-table
            class="elevation-1"
            loading-text="Loading... Please wait"
            v-model="selected"
            :loading="searchFlag"
            :search="search"
            :headers="rows.TableHeader"
            :items="rows.TableData"
            :items-per-page="rows.TableData.ItemsPerPage"
            :item-key="rows.TableData.ItemKey"
            :footer-props="rows.TableData.FooterProps"
            :single-select="false"
            :show-select="false"
            :disable-sort="true"
            :hide-default-footer="true"
          >
            <template v-slot:item.ActiveStatusTxt="{ item }">
              <v-chip
                :color="getActiveStatusColor(item.ActiveStatusTxt)"
                draggable
                dark
                >{{ item.ActiveStatusTxt }}</v-chip
              >
            </template>
            <template v-slot:item.ImagePath="{ item }">
              <img width="100" height="100" :src="item.ImagePath" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="item.ImagePath != ''">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :href="item.ImagePath"
                    target="_blank"
                    class="mx-2"
                    icon
                    dark
                    small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark> mdi-download </v-icon>
                  </v-btn>
                </template>
                <span> Download </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    record: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      rows: {
        TableHeader: {},
        TableOptions: {},
        TableData: [],
      },

      selected: [],
      searchFlag: false,
      search: "",

      LoadingFlag: false,
    };
  },
  computed: {},
  watch: {
    rows: function () {
      console.log("watch rows");
      this.LoadingFlag = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      var record = this.record;
      console.log({ record });
      this.LoadingFlag = true;
      var url1 = "api/members-document-details/show";
      var condition1 = {
        UserInterface: 1,
        MemberId: record.MemberId,
      };
      this.getPageDetails("rows", {}, url1, condition1);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
